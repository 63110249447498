import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { makeStyles } from "@material-ui/core/styles"

import AppBar from "@material-ui/core/AppBar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"

import Button from "@material-ui/core/Button"

import MenuDrawer from "./drawer"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "nyksk/KSKlogo_whiteTransparent.png" }
      ) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.placeholderImage.childImageSharp.fixed}
      alt="logo"
      style={{ marginRight: "10px" }}
    />
  )
}

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const useStyles = makeStyles({
  title: {
    margin: "auto",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
})

function Header({ siteTitle, siteTitleAbbrev, children }) {
  const classes = useStyles()

  return (
    <>
      <HideOnScroll {...children}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <MenuDrawer />
            <Link className={classes.link} to="/" style={{ display: "flex" }}>
              <Image />
              <Typography className={classes.title} variant="h4" noWrap>
                <Hidden xsDown>{siteTitle}</Hidden>
                <Hidden smUp>{siteTitleAbbrev}</Hidden>
              </Typography>
            </Link>
            <div style={{ flex: 1 }} />
            <Hidden mdDown>
              <Link className={classes.link} to="/about-us/">
                <Button className={classes.link}>
                  <Typography variant="h6" noWrap>
                    About Us
                  </Typography>
                </Button>
              </Link>
              <Link className={classes.link} to="/beginners-guide/">
                <Button className={classes.link}>
                  <Typography variant="h6" noWrap>
                    Beginner's Guide
                  </Typography>
                </Button>
              </Link>
              <Link className={classes.link} to="/class-information/">
                <Button className={classes.link}>
                  <Typography variant="h6" noWrap>
                    Class Information
                  </Typography>
                </Button>
              </Link>
              <Link className={classes.link} to="/instructors/">
                <Button className={classes.link}>
                  <Typography variant="h6" noWrap>
                    Instructors
                  </Typography>
                </Button>
              </Link>
              <Link className={classes.link} to="/contact-us/">
                <Button className={classes.link}>
                  <Typography variant="h6" noWrap>
                    Contact Us
                  </Typography>
                </Button>
              </Link>
              <OutboundLink
                className={classes.link}
                href="https://www.facebook.com/NYKenshinkai/"
              >
                <Button className={classes.link}>
                  <Typography variant="h6" noWrap>
                    Facebook
                  </Typography>
                </Button>
              </OutboundLink>
            </Hidden>
            <Link className={classes.link} to="/ja/">
              <Button className={classes.link}>
                <Typography variant="h6" noWrap>
                  日本語
                </Typography>
              </Button>
            </Link>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
