// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-mdx": () => import("./../../../src/pages/about-us.mdx" /* webpackChunkName: "component---src-pages-about-us-mdx" */),
  "component---src-pages-beginners-guide-mdx": () => import("./../../../src/pages/beginners-guide.mdx" /* webpackChunkName: "component---src-pages-beginners-guide-mdx" */),
  "component---src-pages-class-information-mdx": () => import("./../../../src/pages/class-information.mdx" /* webpackChunkName: "component---src-pages-class-information-mdx" */),
  "component---src-pages-contact-us-mdx": () => import("./../../../src/pages/contact-us.mdx" /* webpackChunkName: "component---src-pages-contact-us-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructors-daniel-lee-mdx": () => import("./../../../src/pages/instructors/daniel-lee.mdx" /* webpackChunkName: "component---src-pages-instructors-daniel-lee-mdx" */),
  "component---src-pages-instructors-index-js": () => import("./../../../src/pages/instructors/index.js" /* webpackChunkName: "component---src-pages-instructors-index-js" */),
  "component---src-pages-instructors-joshua-caroll-mdx": () => import("./../../../src/pages/instructors/joshua-caroll.mdx" /* webpackChunkName: "component---src-pages-instructors-joshua-caroll-mdx" */),
  "component---src-pages-instructors-junko-winters-mdx": () => import("./../../../src/pages/instructors/junko-winters.mdx" /* webpackChunkName: "component---src-pages-instructors-junko-winters-mdx" */),
  "component---src-pages-instructors-keiko-umemura-mdx": () => import("./../../../src/pages/instructors/keiko-umemura.mdx" /* webpackChunkName: "component---src-pages-instructors-keiko-umemura-mdx" */),
  "component---src-pages-instructors-ken-kishino-mdx": () => import("./../../../src/pages/instructors/ken-kishino.mdx" /* webpackChunkName: "component---src-pages-instructors-ken-kishino-mdx" */),
  "component---src-pages-instructors-kevin-yong-mdx": () => import("./../../../src/pages/instructors/kevin-yong.mdx" /* webpackChunkName: "component---src-pages-instructors-kevin-yong-mdx" */),
  "component---src-pages-instructors-madoka-ishibashi-mdx": () => import("./../../../src/pages/instructors/madoka-ishibashi.mdx" /* webpackChunkName: "component---src-pages-instructors-madoka-ishibashi-mdx" */),
  "component---src-pages-instructors-masahiko-negita-mdx": () => import("./../../../src/pages/instructors/masahiko-negita.mdx" /* webpackChunkName: "component---src-pages-instructors-masahiko-negita-mdx" */),
  "component---src-pages-instructors-paul-winters-mdx": () => import("./../../../src/pages/instructors/paul-winters.mdx" /* webpackChunkName: "component---src-pages-instructors-paul-winters-mdx" */),
  "component---src-pages-instructors-shin-fukui-mdx": () => import("./../../../src/pages/instructors/shin-fukui.mdx" /* webpackChunkName: "component---src-pages-instructors-shin-fukui-mdx" */),
  "component---src-pages-instructors-toshiki-kishi-mdx": () => import("./../../../src/pages/instructors/toshiki-kishi.mdx" /* webpackChunkName: "component---src-pages-instructors-toshiki-kishi-mdx" */),
  "component---src-pages-instructors-yoichiro-tsunoda-mdx": () => import("./../../../src/pages/instructors/yoichiro-tsunoda.mdx" /* webpackChunkName: "component---src-pages-instructors-yoichiro-tsunoda-mdx" */),
  "component---src-pages-ja-mdx": () => import("./../../../src/pages/ja.mdx" /* webpackChunkName: "component---src-pages-ja-mdx" */)
}

