import React from "react"
import { Helmet } from "react-helmet"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./src/theme.js"

import "fontsource-roboto/latin-300-normal.css"
import "fontsource-roboto/latin-400-normal.css"
import "fontsource-roboto/latin-500-normal.css"
import "fontsource-roboto/latin-700-normal.css"

export default ({ element }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link rel="preconnect" href="https://static.xx.fbcdn.net" crossorigin />
    </Helmet>
    <CssBaseline />
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </>
)
