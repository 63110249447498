import React, { useState } from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

import FacebookIcon from "@material-ui/icons/Facebook"
import NoteIcon from "@material-ui/icons/Note"
import InfoIcon from "@material-ui/icons/Info"
import ClassIcon from "@material-ui/icons/Class"
import PeopleIcon from "@material-ui/icons/People"
import ContactMailIcon from "@material-ui/icons/ContactMail"
import LanguageIcon from "@material-ui/icons/Language"

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const useStyles = makeStyles({
  iconButton: {},
  drawerList: {
    width: 250,
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
  listItemLink: {
    color: "black",
    textDecoration: "none",
  },
})

function ListItemLink(props) {
  const classes = useStyles()
  return (
    <Link className={classes.listItemLink} to={props.href}>
      <ListItem button {...props} />
    </Link>
  )
}

function ExternalListItemLink(props) {
  const classes = useStyles()
  return <ListItem button component="a" {...props} />
}

export default function MenuDrawer() {
  const classes = useStyles()
  const [drawer, setDrawer] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawer(open)
  }

  const drawerList = (
    <div
      className={classes.drawerList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemLink key={1} href="/about-us/">
          <ListItemIcon>
            <InfoIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="About Us" />
        </ListItemLink>
        <ListItemLink key={2} href="/beginners-guide/">
          <ListItemIcon>
            <NoteIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Beginner's Guide" />
        </ListItemLink>
        <ListItemLink key={3} href="/class-information/">
          <ListItemIcon>
            <ClassIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Class Information" />
        </ListItemLink>
        <ListItemLink key={4} href="/instructors/">
          <ListItemIcon>
            <PeopleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Instructors" />
        </ListItemLink>
        <ListItemLink key={5} href="/contact-us/">
          <ListItemIcon>
            <ContactMailIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItemLink>
        <ExternalListItemLink
          key={6}
          href="https://www.facebook.com/NYKenshinkai/"
        >
          <ListItemIcon>
            <FacebookIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Facebook" />
        </ExternalListItemLink>
        <ListItemLink key={7} href="/ja/">
          <ListItemIcon>
            <LanguageIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="日本語" />
        </ListItemLink>
      </List>
    </div>
  )

  return (
    <>
      <IconButton
        className={classes.iconButton}
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        {drawerList}
      </SwipeableDrawer>
    </>
  )
}
