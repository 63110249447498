import SEO from "../../../../src/components/seo";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BoguzenImage from "../../../../src/components/boguzen-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import * as React from 'react';
export default {
  SEO,
  Link,
  AnchorLink,
  BoguzenImage,
  OutboundLink,
  React
};