/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"

import Header from "./components/header"
import Footer from "./components/footer"

import "./layout.css"

const useStyles = makeStyles({
  site: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  siteContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: "#1a237e",
    color: "white",
  },
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          titleAbbrev
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <div className={classes.site}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteTitleAbbrev={data.site.siteMetadata.titleAbbrev}
      />
      <div className={classes.siteContent}>{children}</div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
