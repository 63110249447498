import SEO from "../../../../src/components/seo";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import * as React from 'react';
export default {
  SEO,
  Link,
  OutboundLink,
  Button,
  NavigateNextIcon,
  React
};