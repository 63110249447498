import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import FacebookIcon from "@material-ui/icons/Facebook"

const Footer = () => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography
          component="span"
          style={{ margin: "20px 0 20px 0", flexGrow: "1" }}
        >
          © {new Date().getFullYear()}, Made with{" "}
          <span style={{ color: "red" }}>❤</span>{" "}
          <Hidden smUp>
            <br />
          </Hidden>
          by{" "}
          <OutboundLink
            href="https://www.linkedin.com/in/paul-winters-9371a219/"
            style={{ color: "white" }}
          >
            Paul Winters
          </OutboundLink>
        </Typography>
        <iframe
          title="Facebook like and share buttons"
          src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FNYKenshinkai%2F&width=134&layout=button_count&action=like&size=small&share=true&height=46&appId=269023560381952"
          width="134"
          height="20"
          scrolling="no"
          frameBorder="0"
          allow="encrypted-media"
        />
      </Grid>
    </Container>
  )
}

export default Footer
