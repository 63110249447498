import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h1",
        h3: "h2",
        h4: "h2",
        h5: "h3",
        h6: "h3",
        subtitle1: "h2",
        subtitle2: "h2",
        body1: "p",
        body2: "p",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: "light",
    primary: {
      main: "#0084ff",
    },
    secondary: {
      main: "#1a237e",
    },
  },
  backgroundColor: "white",
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          content: null,
          padding: "0 16px 6px 16px",
        },
      },
    },
    MuiTimelineOppositeContent: {
      root: {
        padding: "0 16px 6px 16px",
      },
    },
    MuiTimelineContent: {
      root: {
        padding: "2px 16px 6px 16px",
      },
    },
  },
})

export default theme
