import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const BoguzenImage = () => {
  const data = useStaticQuery(graphql`
    {
      boguzen: file(relativePath: { eq: "boguzen.png" }) {
        childImageSharp {
          fixed(width: 115, height: 139) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <OutboundLink href="https://www.boguzen.com" title="Boguzen.com">
      <Img fixed={data.boguzen.childImageSharp.fixed} alt="Boguzen.com" />
    </OutboundLink>
  )
}
export default BoguzenImage
